import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import ClassNames from 'classnames';
import componentStyles from './Button.module.scss';
import { FaArrowRight } from 'react-icons/fa';

const Button = ({ id, type, outline, altColor, sm, linkPath, linkExternal, clickEvt, disabled, text, ...props }) => {
	let buttonClass;
	if (outline && altColor) buttonClass = ClassNames(componentStyles.buttonOutline, componentStyles.buttonAltColor);
	else if (outline) buttonClass = componentStyles.buttonOutline;
	else if (altColor) buttonClass = ClassNames(componentStyles.buttonSolid, componentStyles.buttonAltColor);
	else if (sm) buttonClass = ClassNames(componentStyles.buttonSolid, componentStyles.sm);
	else buttonClass = componentStyles.buttonSolid;

	return (
		<>
			{linkPath && !linkExternal && (
				<Link to={linkPath} className={buttonClass}>
					{props.children}
					<span>{text}</span>
					{!props.children && <FaArrowRight aria-hidden='true' className={componentStyles.arrowIcon} />}
				</Link>
			)}

			{linkPath && linkExternal && (
				<a href={linkPath} className={buttonClass}>
					{props.children}
					<span>{text}</span>
					{!props.children && <FaArrowRight aria-hidden='true' className={componentStyles.arrowIcon} />}
				</a>
			)}

			{!linkPath && !linkExternal && (
				<button
					id={id}
					type={type ? type : 'button'}
					className={buttonClass}
					disabled={disabled ? true : false}
					onClick={clickEvt}
				>
					{props.children}
					<span>{text}</span>
					{!props.children && <FaArrowRight aria-hidden='true' className={componentStyles.arrowIcon} />}
				</button>
			)}
		</>
	);
};

export default Button;
