import React, { useState, useEffect } from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { useIntl, Link, FormattedMessage } from 'gatsby-plugin-intl';
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system';
import Classnames from 'classnames';
import componentStyles from './Nav.module.scss';
import Logo from '../../svgs/hcraLogoColor.svg';
import FRLogo from '../../svgs/hcraLogoColor_fr.svg';
import { FaSignInAlt } from 'react-icons/fa';
import { IoMdMegaphone } from 'react-icons/io';
import NavDropdown from '../NavDropdown/NavDropdown';
import TranslationToggle from './TranslationToggle';
import NavSecondTier from './NavSecondTier';
import NavSecondTierBuilder from './NavSecondTierBuilder';
import NavSecondTierBuyer from './NavSecondTierBuyer';

const Nav = ({ view, ...props }) => {
	const intl = useIntl();
	const [activeNavItem, setNavItem] = useState(null);
	const [drawerOpen, toggleDrawer] = useState(false);

	const toggleNavItem = (id) => {
		if (id !== activeNavItem) setNavItem(id);
		else if (id === activeNavItem) setNavItem(null);
	};

	useEffect(() => {
		const clearNav = () => {
			setNavItem(null);
			toggleDrawer(false);
		};
		if (activeNavItem || drawerOpen) {
			window.addEventListener('click', clearNav);
		} else {
			window.removeEventListener('click', clearNav);
		}

		if (drawerOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
		return () => {
			window.removeEventListener('click', clearNav);
			document.body.style.overflow = 'unset';
		};
	}, [activeNavItem, drawerOpen]);

	return (
		<IntlContextConsumer>
			{({ languages, language: currentLocale }) => {
				return (
					<nav aria-label='HCRA Main Navigation' className={componentStyles.nav}>
						<Container>
							<Row className={componentStyles.row}>
								<Col lg={3}>
									<Link to='/' aria-label='Homepage'>
										{currentLocale === 'en' ? (
											<Logo
												className={componentStyles.logo}
												alt='Home Construction Regulatory Authority Logotype'
											/>
										) : (
											<FRLogo
												className={componentStyles.logo}
												alt='Office de Reglementation de la Construction des Logements'
											/>
										)}
									</Link>
								</Col>

								<Hidden xs sm md lg>
									<Col md={9}>
										<nav>
											<ul role='presentation' className={componentStyles.topLinks}>
												<li role='none' className={componentStyles.standalone}>
													<a role='menuitem' href='https://builderportal.hcraontario.ca'>
														<FormattedMessage id='nav.portalLink' />
														<FaSignInAlt className={componentStyles.icon} />
													</a>
												</li>
												<li
													role='none'
													className={`${componentStyles.standalone} ${componentStyles.light}`}
												>
													<Link to='/licensing-compliance/file-a-complaint/'>
														<FormattedMessage id='nav.fileComplaint' />
														<IoMdMegaphone className={componentStyles.icon} />
													</Link>
												</li>
												<li role='none' className={componentStyles.standalone}>
													<TranslationToggle />
												</li>
											</ul>
										</nav>
									</Col>
								</Hidden>
							</Row>

							{/* hamburger menu */}
							<Visible xs sm md lg>
								<button
									id='topnav-menuBtn'
									className={componentStyles.hamburger}
									aria-haspopup='true'
									aria-roledescription='Open the menu'
									aria-label='Open the menu'
									aria-expanded={drawerOpen}
									onClick={() => toggleDrawer(!drawerOpen)}
								>
									<span></span>
									<span></span>
									<span></span>
								</button>
							</Visible>

							<Row>
								<Col md={12}>
									<nav
										aria-label='Main Navigation'
										className={
											drawerOpen
												? Classnames(componentStyles.mainNav, componentStyles.active)
												: componentStyles.mainNav
										}
									>
										<ul role='presentation' className={componentStyles.mainNavList}>
											<li role='none' className={componentStyles.standalone}>
												<Link to='/' role='menuitem'>
													<FormattedMessage id='nav.home' />
												</Link>
											</li>

											<li role='none' className={componentStyles.standalone}>
												<Link to='/about/' role='menuitem'>
													<FormattedMessage id='nav.about' />
												</Link>
											</li>

											<NavDropdown
												id='nav_licensing'
												heading={<FormattedMessage id='nav.licensingComp' />}
												activeItem={activeNavItem}
												onClick={() => toggleNavItem('nav_licensing')}
											>
												<ul
													role='presentation'
													aria-label={intl.formatMessage({
														id: 'nav.licensingComp'
													})}
												>
													<li role='none'>
														<Link to='/licensing-compliance/licensing/' role='menuitem'>
															<FormattedMessage id='nav.licensing' />
														</Link>
													</li>

													<li role='none'>
														<Link
															to='/licensing-compliance/complaints-process/'
															role='menuitem'
														>
															<FormattedMessage id='nav.complaintsProcess' />
														</Link>
													</li>

													<li role='none'>
														<Link
															to='/licensing-compliance/file-a-complaint/'
															role='menuitem'
														>
															<FormattedMessage id='nav.fileComplaint' />
														</Link>
													</li>

													<li role='none'>
														<Link to='/licensing-compliance/directives-advisories/'>
															<FormattedMessage id='nav.directives' />
														</Link>
													</li>

													<li role='none'>
														<Link to='/builder-vendor/become-a-licensed-builder/do-i-need-a-licence/'>
															<FormattedMessage id='nav.becomeBuilder' />
														</Link>
													</li>

													<li role='none'>
														<Link to='/licensing-compliance/regulatory-activites-enforcement/'>
															<FormattedMessage id='nav.regActivites' />
														</Link>
													</li>
												</ul>
											</NavDropdown>

											<li role='none' className={componentStyles.standalone}>
												<Link to='/news-and-events/' role='menuitem'>
													<FormattedMessage id='nav.news' />
												</Link>
											</li>
										</ul>
									</nav>
								</Col>
							</Row>
						</Container>

						<Hidden xs sm md lg style={{ width: `100%` }}>
							{view === 'buyer' && <NavSecondTierBuyer />}
							{view === 'builder' && <NavSecondTierBuilder />}
							{view !== 'builder' && view !== 'buyer' && <NavSecondTier />}
						</Hidden>
					</nav>
				);
			}}
		</IntlContextConsumer>
	);
};

export default Nav;
