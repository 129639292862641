import React from 'react';
import componentStyles from './NavDropdown.module.scss';
import ClassNames from 'classnames';

const NavDropdown = ({ id, heading, activeItem, onClick, size, lastItem, ...props }) => {
	const toggledOpen = id === activeItem ? true : false;

	let className = '';
	let classNameOpen = '';
	if (lastItem) {
		className = ClassNames(componentStyles.dropDown, componentStyles.lastItem);
		classNameOpen = ClassNames(componentStyles.dropDown, componentStyles.active, componentStyles.lastItem);
	} else {
		className = componentStyles.dropDown;
		classNameOpen = ClassNames(componentStyles.dropDown, componentStyles.active);
	}

	return (
		<li role='none' className={toggledOpen ? classNameOpen : className}>
			<button
				href='#'
				aria-haspopup='true'
				aria-expanded={toggledOpen}
				onClick={onClick}
				className={size === 'sm' ? componentStyles.smBtn : undefined}
			>
				<span>{heading}</span>
			</button>
			{props.children}
		</li>
	);
};

export default NavDropdown;
