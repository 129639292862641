import React from 'react';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';
import componentStyles from './Nav.module.scss';

const languageName = {
	en: 'English',
	fr: 'Français'
};

const TranslationToggle = (props) => {
	return (
		<IntlContextConsumer>
			{({ languages, language: currentLocale }) => {
				const oppLang = currentLocale === 'en' ? 'fr' : 'en';

				return (
					<button
						lang={oppLang}
						role='menuitem'
						className={componentStyles.translationToggle}
						aria-label='Change language'
						onClick={() => changeLocale(oppLang)}
					>
						<span>{languageName[oppLang]}</span>
					</button>
				);
			}}
		</IntlContextConsumer>
	);
};

export default TranslationToggle;
