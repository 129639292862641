import React from 'react';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import componentStyles from './Nav.module.scss';
import { FaChevronRight } from 'react-icons/fa';

import HomeIcon from '../../svgs/houseBlue.svg';
import Crane from '../../svgs/craneHome-blue.svg';
import OBDlogoWhite from '../../svgs/OBDlogo-blue.svg';

const NavSecondTier = () => {
	return (
		<section className={componentStyles.secondTier}>
			<Link to='/new-home-buyer/dashboard/' className={componentStyles.secondTierLink}>
				<HomeIcon className={componentStyles.svg} />
				<div>
					<span>
						<FormattedMessage id='nav.buyerDashA' />
					</span>
					<br />
					<b>
						<FormattedMessage id='nav.buyerDashB' />
					</b>
				</div>
				<FaChevronRight className={componentStyles.icon} />
			</Link>

			<Link to='/builder-vendor/dashboard/' className={componentStyles.secondTierLink}>
				<Crane className={componentStyles.svg} />
				<div>
					<span>
						<FormattedMessage id='nav.builderDashA' />
					</span>
					<br />
					<b>
						<FormattedMessage id='nav.builderDashB' />
					</b>
				</div>
				<FaChevronRight className={componentStyles.icon} />
			</Link>

			<a href='https://obd.hcraontario.ca' className={componentStyles.secondTierLink}>
				<OBDlogoWhite className={componentStyles.svg} />
				<div>
					<span>
						<FormattedMessage id='nav.obdA' />
					</span>
					<br />
					<b>
						<FormattedMessage id='nav.obdB' />
					</b>
				</div>
				<FaChevronRight className={componentStyles.icon} />
			</a>
		</section>
	);
};

export default NavSecondTier;
